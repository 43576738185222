import React, { useState } from 'react';

function ImageUpload({ onUploadComplete, onUploadError }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleCapture = (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    setSelectedFile(file);
    setPreviewUrl(URL.createObjectURL(file));
    setError(''); // Clear any existing errors
  };

  const uploadImage = async () => {
    if (!selectedFile) {
      setError("Please select an image first!");
      return;
    }

    setIsLoading(true);
    const formData = new FormData();
    formData.append('image', selectedFile);

    // Optional: Add image resizing here if necessary

    try {
      const response = await fetch('https://ca-be-tcgscanner.grayrock-fa6ac7a6.westus2.azurecontainerapps.io/api/upload3', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) throw new Error('Network response was not ok');

      const data = await response.json();
      onUploadComplete(data);
      setSuccessMessage('Image uploaded successfully!');
    } catch (error) {
      console.error('Error uploading the image:', error);
      setError(`Failed to upload the image: ${error.message}`);
      onUploadError();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
      <input type="file" accept="image/*" capture="environment" onChange={handleCapture} multiple={false} />
      {previewUrl && <img src={previewUrl} alt="Preview" style={{ maxWidth: '100%', height: 'auto' }} />}
      {selectedFile && !isLoading && (
        <button onClick={uploadImage}>Upload Image</button>
      )}
      {isLoading && <p>Uploading...</p>}
    </div>
  );
}

export default ImageUpload;
