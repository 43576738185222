import React, { useState } from 'react';

function CardDetails({ cardData, isError }) {
  const [showModal, setShowModal] = useState(false);

  if (isError) {
    return <div>An error occurred while fetching the card details. Please try again.</div>;
  }

  if (!cardData) {
    return <div>No card data available. Please upload an image to analyze.</div>;
  }

  const { images, name, cardmarket } = cardData;

  const handleImageClick = () => {
    setShowModal(true);
  };

  return (
    <div>
      <h2>{name}</h2>
      <img src={images.small} alt={name} style={{ maxWidth: '100%', width: '300px', cursor: 'pointer' }} onClick={handleImageClick} />
      {showModal && (
        <div onClick={() => setShowModal(false)} style={{ position: 'fixed', top: 0, left: 0, height: '100vh', width: '100vw', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <img src={images.large} alt={name} style={{ maxWidth: '90%', maxHeight: '90%' }} />
        </div>
      )}
      <p>Updated at: {cardmarket.updatedAt}</p>
      {/* Card Pricing */}
      <div>
        <h3>Card Market Prices:</h3>
        <ul>
          {Object.entries(cardmarket.prices).map(([key, value]) => (
            <li key={key}>{`${key.replace(/([A-Z])/g, ' $1')}: $${value}`}</li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default CardDetails;
