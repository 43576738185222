import React, { useState } from 'react';
import ImageUpload from './components/ImageUpload';
import CardDetails from './components/CardDetails';

function App() {
  const [cardData, setCardData] = useState(null);
  const [isError, setIsError] = useState(false);

  const handleUploadComplete = (data) => {
    setCardData(data.data);
    setIsError(false); // Reset error state on successful upload
  };

  const handleUploadError = () => {
    setIsError(true);
    setCardData(null); // Optionally clear previous card data on error
  };

  return (
    <div>
      <ImageUpload onUploadComplete={handleUploadComplete} onUploadError={handleUploadError} />
      <CardDetails cardData={cardData} isError={isError} />
    </div>
  );
}

export default App;

